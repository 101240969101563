.errored_field {
    border: 1px solid #c0392b !important;
    background: #f9ebec !important;
}

.error_message {
    color: #c0392b;
    font-size: 12px;
    margin-top: 5px;
}

.dropdown-options-preview {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 999;
    display: none;
    top: 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);   
    border-radius: 0px !important; 
    background: #333;
}

.dropdown-medicamentos .dropdown-item{
    padding: 10px;
    cursor: pointer;
    font-size: 0.8rem;
    color: #bfbdbd;
}

.dropdown-item:hover{
    background: #444;
    color: #fff;
}

